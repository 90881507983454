import React, {useRef, useEffect } from 'react'
import './Footer.css'
import {gsap} from 'gsap'




function Footer() {
    const footer = useRef()

  useEffect(()=>{
   
   gsap.from(footer.current, {
      duration: 2,
      delay: 3,
      opacity: 0,
      ease: 'power2.inOut'
    })
},[])

function backToTop(){
    window.scrollTo(0, 0)
  }
  return (
    <div ref={footer} className='footer-container'>
      <p>
        Copyright 2022 High Timber Taxidermy
        <a className='backtop' onClick={backToTop}>    Back to the top</a>
      </p>
      <ul>
        <li>Phone: 303-803-3864</li>
        <li>Email: TheHighTImberTaxidermist@Gmail.com</li>
      </ul>
    </div>
  )
}

export default Footer
